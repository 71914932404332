import { Component } from 'react';
import Overview from './overview/Overview';
import Configurator from './configurator/Configurator';
import { withRouter } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Summary } from './configurator/Summary';
import { Login } from './login/Login';

interface IProps extends RouteComponentProps {}

interface IState {
  filters: any;
  products: any;
}

class Content extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filters: undefined,
      products: undefined,
    };
  }

  render() {
    return (
      <Switch>
        <Route exact path="/">
          <Login
            onReady={(filters: any, products: any) => {
              this.setState({
                filters,
                products,
              });

              this.props.history.push('overview');
            }}
          />
        </Route>

        {this.state.filters && this.state.products && (
          <>
            <Route exact path="/overview">
              <Overview
                filters={this.state.filters}
                products={this.state.products}
              />
            </Route>
            <Route exact path="/configurator" component={Configurator} />
            <Route exact path="/summary" component={Summary} />
          </>
        )}

        <Redirect to="/" />
      </Switch>
    );
  }
}

export default withRouter(Content);
