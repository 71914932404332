import { Component, ReactNode } from 'react';
import { Container } from 'reactstrap';

interface IProps {
  children?: ReactNode;
  className?: string;
}

export class HorizontalScrollContainer extends Component<IProps> {
  componentDidMount() {
    const scrollContainer = document.getElementById('scrollContainer');

    scrollContainer?.addEventListener('click', (event) => {
      const element: HTMLElement = event.target as HTMLElement;

      element.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      });
    });
  }

  render() {
    return (
      <Container
        id="scrollContainer"
        className={`d-flex p-0 ${this.props.className}`}
        style={{
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {this.props.children}
      </Container>
    );
  }
}
