import { Component, CSSProperties, ReactNode } from 'react';
import { Button } from 'reactstrap';

interface IProps {
  className?: string;
  color?: string;
  style?: CSSProperties;
  children?: ReactNode;
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export class ToggleButton extends Component<IProps> {
  render() {
    return (
      <Button
        color={this.props.color}
        className={`toggle-button ${this.props.className}`}
        style={this.props.style}
        outline
        active={this.props.active}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </Button>
    );
  }
}
