import { Component } from 'react';
import logo from './logo.svg';
import { NavbarBrand } from 'reactstrap';

interface IProps {
  href?: string;
}

export class Logo extends Component<IProps> {
  render() {
    return (
      <NavbarBrand className="p-0" href={this.props.href || '#/overview'}>
        <img src={logo} />
      </NavbarBrand>
    );
  }
}
