import { Component } from 'react';
import { Button, Card, CardBody, CardText, CardTitle } from 'reactstrap';

interface IProps {
  onClick: () => void;
  title?: string;
  text?: string;
  imageUrl?: string;
}

export class Tile extends Component<IProps> {
  render() {
    return (
      <Card
        className="h-100"
        onClick={this.props.onClick}
        style={{ cursor: 'pointer' }}
      >
        {this.props.imageUrl && (
          <img
            src={this.props.imageUrl}
            height="200"
            className="bg-secondary"
            style={{ objectFit: 'contain' }}
          />
        )}
        <CardBody className="d-flex flex-column align-items-start p-4">
          {this.props.title && (
            <CardTitle tag="h6">{this.props.title}</CardTitle>
          )}

          {this.props.text && (
            <CardText style={{ color: '#666666', fontWeight: 400 }}>
              {this.props.text}
            </CardText>
          )}

          <Button
            color="primary"
            onClick={this.props.onClick}
            className="mt-auto text-uppercase d-inline-block w-auto"
          >
            {'> Configure Product'}
          </Button>
        </CardBody>
      </Card>
    );
  }
}
