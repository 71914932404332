import React from 'react';
import { Container } from 'reactstrap';
import Content from './pages/Content';

function App() {
  return (
    <Container fluid className="app d-flex flex-column p-0 overflow-hidden">
      <Content />
    </Container>
  );
}

export default App;
