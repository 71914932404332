import { Component } from 'react';
import { Button, Col, Form, Spinner } from 'reactstrap';
import cover from './cover.jpg';
import { Logo } from '../header/Logo';
import {withRouter} from "react-router";

interface IProps {
  onReady: (filter: any, products: any) => void;
}

interface IState {
  isLoading: boolean;
}

export class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadConfigs();
  }

  loadConfigs = async () => {
    this.setState({ isLoading: true });

    try {
      const [filters, products] = await Promise.all([
        (await fetch(`./configs/filters.json`)).json(),
        (await fetch(`./configs/products.json`)).json(),
      ]);

      this.props.onReady(filters, products);
    } catch (event) {}

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <>
        <div className="p-3 p-md-4 pb-4 position-absolute">
          <Logo />
        </div>

        <Form className="row h-100 justify-content-center p-3 p-md-0">
          <Col className="col-5 h-100 bg-secondary d-none d-md-flex align-items-center justify-content-center p-0">
            <img
              src={cover}
              className="h-100 w-100"
              style={{ objectFit: 'cover' }}
            />

            <div
              className="position-absolute"
              style={{ bottom: '20px', left: '20px' }}
            >
              <h2 className="bg-secondary bg-opacity-50">
                Your Partner in <br /> Materials Testing
              </h2>
            </div>
          </Col>

          <Col className="col-md-7 my-md-auto">
            <div
              className="d-flex flex-column h-100 mx-auto"
              style={{ maxWidth: '412px' }}
            >
              <h2>Welcome to ZwickRoell</h2>

              <div className="mt-auto d-md-none" />

              <Button
                color="primary"
                className="w-100 d-flex align-items-center justify-content-center"
              >
                {'Preparing the configurator...'}
                {this.state.isLoading && <Spinner size="sm" className="ms-2" />}
              </Button>
            </div>
          </Col>
        </Form>
      </>
    );
  }
}