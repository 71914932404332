import { Component, createRef } from 'react';
import { Button, Card, CardBody, Col, Container, List, Row } from 'reactstrap';
import { QRCodeSVG } from 'qrcode.react';
import { RouteComponentProps } from 'react-router-dom';
import { ToggleButton } from '../../components/ToggleButton';
import { CubeIcon } from './CubeIcon';
import { Logo } from '../header/Logo';
import { IoCloseOutline } from 'react-icons/io5';
import '../../styles/printSummary.scss';

interface LocationState {
  product: any;
  variant: any;
}

interface IProps extends RouteComponentProps<{}, any, LocationState> {}

interface IState {
  isARCompatible?: boolean;
  isViewerReady?: boolean;
  isARModalOpen?: boolean;
  arPresetId?: any;
}

export class Summary extends Component<IProps, IState> {
  viewer: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isARModalOpen: false,
      arPresetId:
        this.props.location.state.variant.arTrueSizePresetId ||
        this.props.location.state.variant.arDeskSizePresetId,
    };

    this.viewer = createRef();
  }

  componentDidMount() {
    window.addEventListener(
      'message',
      (event) => {
        if (!this.viewer) {
          return;
        }

        if (event.data.action === 'viewerStarted') {
          this.viewer.current.contentWindow.postMessage(
            { action: 'isARCompatible' },
            '*',
          );

          this.setState({ isViewerReady: true });
        } else if (event.data.actionCalled === 'isARCompatible') {
          this.setState({
            isARCompatible: event.data.isARCompatible,
          });
        }
      },
      false,
    );
  }

  render() {
    // prepare iframe url
    const viewerUrl = new URL(this.props.location.state.variant.viewerUrl);
    const searchParams = viewerUrl.searchParams;
    searchParams.set('viewer_api', 'true');
    searchParams.set('controls', 'false');
    searchParams.set('white_label', 'true');
    searchParams.set('autoplay', 'true');

    return (
      <>
        <Row className="g-3 p-3 p-md-4">
          <Col className="col-12 col-md-6">
            <Logo />
          </Col>

          <Col
            id="action-buttons-container"
            className="col-12 col-md-6 d-flex justify-content-md-end"
          >
            <Button
              outline
              color="primary"
              className="text-uppercase me-2"
              onClick={() => {
                this.props.history.push({
                  pathname: `/configurator`,
                  state: {
                    product: this.props.location.state.product,
                    variant: this.props.location.state.variant,
                  },
                });
              }}
            >
              {'> Edit'}
            </Button>

            <Button
              outline
              color="primary"
              className="text-uppercase me-2"
              onClick={() => {
                window.print();
              }}
            >
              {'> Save as PDF'}
            </Button>

            {/* TODO <Button color="primary" className="text-uppercase">
            {'> Share'}
          </Button>*/}
          </Col>
        </Row>

        <Row
          id="scroll-container"
          className="d-block g-2"
          style={{ overflowX: 'hidden', overflowY: 'auto' }}
        >
          <Col
            id="viewer-container"
            className="col-12 col-md-6 float-md-start position-relative p-0"
            style={{ height: '70vh' }}
          >
            <iframe
              id="viewer"
              src={viewerUrl.toString()}
              height="100%"
              width="100%"
              className="bg-secondary"
              ref={this.viewer}
              onLoad={() => {
                this.viewer?.current.contentWindow.postMessage(
                  { action: 'subscribe' },
                  '*',
                );
              }}
            />

            <img
              id="thumbnail"
              src={this.props.location.state.variant.thumbnailUrl}
              height="100%"
              width="100%"
              className="bg-secondary"
              style={{ objectFit: 'scale-down', display: 'none' }}
            />

            {this.state.isViewerReady && (
              <Container
                id="viewer-buttons-container"
                className="d-flex justify-content-center position-absolute bottom-0"
              >
                <ToggleButton
                  color="primary"
                  className="configurator-button text-uppercase d-flex align-items-center mb-4"
                  onClick={() => {
                    this.setState({ isARModalOpen: true });
                  }}
                  active={this.state.isARModalOpen}
                >
                  <CubeIcon />
                  <span className="ms-2">See in your room</span>
                </ToggleButton>
              </Container>
            )}

            {this.state.isARModalOpen && (
              <div
                id="ar-modal"
                className="d-flex position-absolute bottom-0 h-100 w-100 justify-content-center align-items-center"
                style={{ zIndex: 999 }}
              >
                <Card
                  className="bg-white p-2 position-relative"
                  style={{ maxWidth: '80%' }}
                >
                  <Button
                    color="none"
                    className="position-absolute"
                    style={{ color: '#666666', top: 0, right: 0 }}
                    onClick={() =>
                      this.setState({
                        isARModalOpen: false,
                      })
                    }
                  >
                    <IoCloseOutline style={{ width: '24px', height: '24px' }} />
                  </Button>

                  <CardBody className="justify-content-center">
                    <Row className="g-2">
                      <Col>
                        {this.state.isARCompatible ? (
                          <h3 className="mb-4 text-center">
                            See the{' '}
                            <span className="text-primary">
                              {this.props.location.state.variant.name}
                            </span>{' '}
                            in AR{' '}
                          </h3>
                        ) : (
                          <h3 className="mb-4">
                            Scan the QR-Code with your smartphone to see the{' '}
                            <span className="text-primary">
                              {this.props.location.state.variant.name}
                            </span>{' '}
                            in AR
                          </h3>
                        )}

                        <Row className="g-2">
                          {this.props.location.state.variant
                            .arTrueSizePresetId && (
                            <Col className="col-12 col-md-6">
                              {this.state.isARCompatible ? (
                                <Button
                                  color="primary"
                                  outline
                                  className="text-uppercase w-100"
                                  onClick={() =>
                                    window.open(
                                      `${
                                        viewerUrl.origin + viewerUrl.pathname
                                      }?preset=${
                                        this.props.location.state.variant
                                          .arTrueSizePresetId
                                      }&ar_only=true`,
                                      '_blank',
                                    )
                                  }
                                >
                                  Open in 1:1 (True size)
                                </Button>
                              ) : (
                                <ToggleButton
                                  color="primary"
                                  className="text-uppercase w-100 text-black border-secondary"
                                  active={
                                    this.state.arPresetId ===
                                    this.props.location.state.variant
                                      .arTrueSizePresetId
                                  }
                                  onClick={() =>
                                    this.setState({
                                      arPresetId:
                                        this.props.location.state.variant
                                          .arTrueSizePresetId,
                                    })
                                  }
                                >
                                  1:1 true size
                                </ToggleButton>
                              )}
                            </Col>
                          )}

                          {this.props.location.state.variant
                            .arDeskSizePresetId && (
                            <Col className="col-12 col-md-6">
                              {this.state.isARCompatible ? (
                                <Button
                                  color="primary"
                                  outline
                                  className="text-uppercase w-100"
                                  onClick={() =>
                                    window.open(
                                      `${
                                        viewerUrl.origin + viewerUrl.pathname
                                      }?preset=${
                                        this.props.location.state.variant
                                          .arDeskSizePresetId
                                      }&ar_only=true`,
                                      '_blank',
                                    )
                                  }
                                >
                                  Open in 1:16 (desk size)
                                </Button>
                              ) : (
                                <ToggleButton
                                  color="primary"
                                  className="text-uppercase w-100 text-black border-secondary"
                                  active={
                                    this.state.arPresetId ===
                                    this.props.location.state.variant
                                      .arDeskSizePresetId
                                  }
                                  onClick={() =>
                                    this.setState({
                                      arPresetId:
                                        this.props.location.state.variant
                                          .arDeskSizePresetId,
                                    })
                                  }
                                >
                                  1:16 desk size
                                </ToggleButton>
                              )}
                            </Col>
                          )}
                        </Row>
                      </Col>

                      {!this.state.isARCompatible && (
                        <Col className="col-12 col-md-6 d-flex flex-column align-items-center">
                          <QRCodeSVG
                            value={`${
                              viewerUrl.origin + viewerUrl.pathname
                            }?preset=${this.state.arPresetId}&ar_only=true`}
                            style={{ border: 'solid 1px' }}
                            className="border-secondary mb-2"
                            includeMargin={true}
                          />

                          <p
                            className="text-muted text-center w-50"
                            style={{ fontSize: '12px' }}
                          >
                            Requires iOS 13+, iPadOS 13+ or Android with ARCore
                            1.9+
                          </p>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </div>
            )}
          </Col>

          <Col
            id="info-container"
            className="col-12 col-md-6 float-md-end p-3 p-md-5 pt-0 pt-md-0"
          >
            <h1 className="mb-3">{this.props.location.state.variant.name}</h1>

            <div style={{ whiteSpace: 'pre-line' }}>
              {this.props.location.state.variant.description}
            </div>

            <hr />

            <h2 className="mb-4">Specification</h2>

            <Row className="g-2">
              {this.props.location.state.variant.industries?.length > 0 && (
                <Col className="col-6">
                  <h6>Industries</h6>

                  <List>
                    {this.props.location.state.variant.industries.map(
                      (industry: string) => (
                        <li className="my-2">{industry}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.types?.length > 0 && (
                <Col className="col-6">
                  <h6>Type of test</h6>

                  <List>
                    {this.props.location.state.variant.types.map(
                      (type: string) => (
                        <li className="my-2">{type}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.specimenRange?.length > 0 && (
                <Col className="col-6">
                  <h6>Specimen range</h6>

                  <List>
                    {this.props.location.state.variant.specimenRange.map(
                      (specimenRange: string) => (
                        <li className="my-2">{specimenRange}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.temperatureRange?.length >
                0 && (
                <Col className="col-6">
                  <h6>Temperature range</h6>

                  <List>
                    {this.props.location.state.variant.temperatureRange.map(
                      (temperatureRange: string) => (
                        <li className="my-2">{temperatureRange}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.standards?.length > 0 && (
                <Col className="col-6">
                  <h6>Standards</h6>

                  <List>
                    {this.props.location.state.variant.standards.map(
                      (standard: string) => (
                        <li className="my-2">{standard}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.versions?.length > 0 && (
                <Col className="col-6">
                  <h6>Versions</h6>

                  <List>
                    {this.props.location.state.variant.versions.map(
                      (version: string) => (
                        <li className="my-2">{version}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.specialFeatures?.length >
                0 && (
                <Col className="col-6">
                  <h6>Special Features</h6>

                  <List>
                    {this.props.location.state.variant.specialFeatures.map(
                      (specialFeature: string) => (
                        <li className="my-2">{specialFeature}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.methods?.length > 0 && (
                <Col className="col-6">
                  <h6>Methods</h6>

                  <List>
                    {this.props.location.state.variant.methods.map(
                      (method: string) => (
                        <li className="my-2">{method}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.testMethods?.length > 0 && (
                <Col className="col-6">
                  <h6>Test Methods</h6>

                  <List>
                    {this.props.location.state.variant.testMethods.map(
                      (testMethod: string) => (
                        <li className="my-2">{testMethod}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.testVolumes?.length > 0 && (
                <Col className="col-6">
                  <h6>Test Volumes</h6>

                  <List>
                    {this.props.location.state.variant.testVolumes.map(
                      (testVolume: string) => (
                        <li className="my-2">{testVolume}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.testLoads?.length > 0 && (
                <Col className="col-6">
                  <h6>Test Loads</h6>

                  <List>
                    {this.props.location.state.variant.testLoads.map(
                      (testLoad: string) => (
                        <li className="my-2">{testLoad}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}

              {this.props.location.state.variant.advantages?.length > 0 && (
                <Col className="col-6">
                  <h6>Advantages</h6>

                  <List>
                    {this.props.location.state.variant.advantages.map(
                      (advantage: string) => (
                        <li className="my-2">{advantage}</li>
                      ),
                    )}
                  </List>
                </Col>
              )}
            </Row>

            {this.props.location.state.variant.componentsAndAccessories
              ?.length > 0 && (
              <>
                <hr />

                <h2 className="mb-4">Components and accessories</h2>

                <List>
                  {this.props.location.state.variant.componentsAndAccessories.map(
                      (component: any) => (
                          <li className="my-2">{component.name}</li>
                      ),
                  )}
                </List>

                {/*{this.props.location.state.variant.componentsAndAccessories.map(*/}
                {/*  (component: any) => (*/}
                {/*    <Row className="align-items-center my-2">*/}
                {/*      <Col className="col-4">*/}
                {/*        <img*/}
                {/*          src={component.thumbnailUrl}*/}
                {/*          height="126px"*/}
                {/*          width="100%"*/}
                {/*          className="bg-secondary"*/}
                {/*          style={{ objectFit: 'contain' }}*/}
                {/*        />*/}
                {/*      </Col>*/}

                {/*      <Col className="col-8 justify-content-center">*/}
                {/*        <h6>{component.name}</h6>*/}
                {/*        <div style={{ whiteSpace: 'pre-line' }}>*/}
                {/*          {component.description}*/}
                {/*        </div>*/}
                {/*      </Col>*/}
                {/*    </Row>*/}
                {/*  ),*/}
                {/*)}*/}
              </>
            )}

            {this.props.location.state.variant.applications?.length > 0 && (
              <>
                <hr />

                <h2 className="mb-4">Applications</h2>

                <List>
                  {this.props.location.state.variant.applications.map(
                      (application: any) => (
                          <li className="my-2">{application.name}</li>
                      ),
                  )}
                </List>

                {/*{this.props.location.state.variant.applications.map(*/}
                {/*  (application: any) => (*/}
                {/*    <Row className="align-items-center my-2">*/}
                {/*      <Col className="col-4">*/}
                {/*        <img*/}
                {/*          src={application.thumbnailUrl}*/}
                {/*          height="126px"*/}
                {/*          width="100%"*/}
                {/*          className="bg-secondary"*/}
                {/*          style={{ objectFit: 'contain' }}*/}
                {/*        />*/}
                {/*      </Col>*/}

                {/*      <Col className="col-8 d-flex flex-column justify-content-center">*/}
                {/*        <h6>{application.name}</h6>*/}
                {/*        <div style={{ whiteSpace: 'pre-line' }}>*/}
                {/*          {application.description}*/}
                {/*        </div>*/}
                {/*      </Col>*/}
                {/*    </Row>*/}
                {/*  ),*/}
                {/*)}*/}
              </>
            )}
          </Col>

          {(this.props.location.state.variant.arTrueSizePresetId ||
            this.props.location.state.variant.arDeskSizePresetId) && (
            <Col
              id="qr-code-container"
              className="col-12 col-md-6 float-md-start p-3 p-md-5 pt-0 pt-md-0"
            >
              <Row className="text-center text-md-start">
                <Col className="col-12 col-md-6">
                  <h5>
                    Scan the QR-Code with your smartphone to see{' '}
                    <span className="text-primary">
                      {this.props.location.state.variant.name}
                    </span>{' '}
                    in AR
                  </h5>
                </Col>

                <Col className="col-12 col-md-6 d-flex flex-column align-items-center">
                  <QRCodeSVG
                    value={`${viewerUrl.origin + viewerUrl.pathname}?preset=${
                      this.props.location.state.variant.arTrueSizePresetId ||
                      this.props.location.state.variant.arDeskSizePresetId
                    }&ar_only=true`}
                    style={{ border: 'solid 1px' }}
                    className="border-secondary mb-2"
                    includeMargin={true}
                  />

                  <p
                    className="text-muted text-center w-50"
                    style={{ fontSize: '12px' }}
                  >
                    Requires iOS 13+, iPadOS 13+ or Android with ARCore 1.9+
                  </p>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </>
    );
  }
}
