import { Component } from 'react';

export class CubeIcon extends Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0833 5.06479L8.99997 8.99998M8.99997 8.99998L1.91664 5.06479M8.99997 8.99998L9 16.9167M16.5 12.3821V5.61788C16.5 5.33234 16.5 5.18957 16.4579 5.06224C16.4207 4.94959 16.3599 4.84619 16.2795 4.75895C16.1886 4.66033 16.0638 4.591 15.8142 4.45233L9.64753 1.02641C9.41119 0.895108 9.29302 0.829459 9.16788 0.803721C9.05712 0.780941 8.94288 0.780941 8.83213 0.803721C8.70698 0.829459 8.58881 0.895108 8.35248 1.02641L2.18581 4.45233C1.93621 4.591 1.8114 4.66034 1.72053 4.75895C1.64013 4.84619 1.57929 4.94959 1.54207 5.06224C1.5 5.18957 1.5 5.33234 1.5 5.61788V12.3821C1.5 12.6677 1.5 12.8104 1.54207 12.9378C1.57929 13.0504 1.64013 13.1538 1.72053 13.2411C1.8114 13.3397 1.93621 13.409 2.18581 13.5477L8.35248 16.9736C8.58881 17.1049 8.70698 17.1705 8.83213 17.1963C8.94288 17.2191 9.05712 17.2191 9.16788 17.1963C9.29302 17.1705 9.41119 17.1049 9.64753 16.9736L15.8142 13.5477C16.0638 13.409 16.1886 13.3397 16.2795 13.2411C16.3599 13.1538 16.4207 13.0504 16.4579 12.9378C16.5 12.8104 16.5 12.6677 16.5 12.3821Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
