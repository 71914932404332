import { Component } from 'react';

export class MeasureIcon extends Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 16.5V1.5M1.5 16.5V1.5M4.41667 9H13.5833M13.5833 11.5L13.5833 6.5M4.41667 11.5L4.41667 6.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
